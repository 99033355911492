import { FormControlLabel, Checkbox, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Rodal from "rodal";
import "toastr/build/toastr.min.css";
import { fetchPermissions } from "../../../../../services/OutletUsers/outletUserService.js";
import CenterCircularProgress from "../../../../Common/CircularProgress/CenterCircularProgress";
import RodalHeader from "../../../../Common/Headers/RodalHeader";
import ModalFooterButton from "./../common/ModalFooterButton";

const PermissionsRodal = ({
  resources,
  showRodalPermissions,
  handleCloseRodalPermissions,
  handleConfirmSelectedPermissions,
  selectedPermissions,
  selectedPermissionsName,
}) => {
  const [selectedPermissionsChecked, setSelectedPermissionsChecked] =
    useState(selectedPermissions);
  const [selectedPermissionsCheckedName, setSelectedPermissionsCheckedName] =
    useState(selectedPermissionsName);
  const [permissions, setPermissions] = useState([]);
  const [loadingPermisions, setLoadingPermisions] = useState(true);

  const getPermissions = async () => {
    const permissionsSorted = await fetchPermissions();
    setPermissions(permissionsSorted);
    setLoadingPermisions(false);
  };

  useEffect(() => {
    getPermissions();
  }, []);

  const closeRodalPermissions = () => {
    setSelectedPermissionsChecked(selectedPermissions);
    setSelectedPermissionsCheckedName(selectedPermissionsName);

    handleCloseRodalPermissions();
  };

  const getPermissionNamesChecked = (newSelectedPermissionsCheckedArray) => {
    return newSelectedPermissionsCheckedArray
      .map((id) => {
        const permission = permissions.find((x) => x.id === id);
        return permission ? permission.description : "";
      })
      .filter((desc) => desc !== "");
  };

  const handleSelectPermissions = (e) => {
    const selectedPermissionId = parseInt(e.target.value);
    const permission = permissions.find((x) => x.id === selectedPermissionId);

    if (!permission) return;

    let newSelectedPermissionsChecked = new Set(selectedPermissionsChecked);

    if (e.target.checked) {
      newSelectedPermissionsChecked.add(selectedPermissionId);
    } else {
      if (newSelectedPermissionsChecked.has(selectedPermissionId)) {
        newSelectedPermissionsChecked.delete(selectedPermissionId);
      }
    }

    const newSelectedPermissionsCheckedArray = Array.from(
      newSelectedPermissionsChecked
    );
    const newSelectedPermissionNamesCheckedArray = getPermissionNamesChecked(
      newSelectedPermissionsCheckedArray
    ).join(", ");

    setSelectedPermissionsChecked(newSelectedPermissionsCheckedArray);
    setSelectedPermissionsCheckedName(newSelectedPermissionNamesCheckedArray);
  };

  const renderPermissions = () => {
    const permissionsMapped = permissions.map((x) => (
      <Form.Group key={`permissions${x.id}`} className=" mb-0 text-left">
        <FormControlLabel
          className="col-md-11 text-right label-mt my-auto p-0"
          control={
            <Checkbox
              id={`permissions-indicators-is-active${x.id}`}
              name={`permissions-${x.id}`}
              style={{ padding: "5px 9px!important" }}
              checked={selectedPermissionsChecked.indexOf(x.id) !== -1}
              onChange={handleSelectPermissions}
              value={x.id}
              color="primary"
            />
          }
          label={x.description}
        />
      </Form.Group>
    ));
    return permissionsMapped;
  };

  return (
    <Rodal
      visible={showRodalPermissions}
      onClose={() => closeRodalPermissions()}
      width={500}
      height={550}
    >
      {loadingPermisions ? (
        <CenterCircularProgress />
      ) : (
        <Box>
          <RodalHeader title={resources["Permissions"]} />
          <Box className="modal-body mb-0 py-2">
            <Form.Group
              className=" overflow-auto "
              style={{ height: "400px", maxHeight: "400px" }}
            >
              {renderPermissions()}
            </Form.Group>
          </Box>
          <ModalFooterButton
            id="btnOkPermissions"
            onClick={() =>
              handleConfirmSelectedPermissions(
                selectedPermissionsChecked,
                selectedPermissionsCheckedName
              )
            }
            buttonText={resources["Ok"]}
          />
          <span className="rodal-close" />
        </Box>
      )}
    </Rodal>
  );
};

export default PermissionsRodal;
