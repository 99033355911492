import React from 'react';
import ActionButton from "../../../../Common/Buttons/ActionButton.js";
import { Box } from "@material-ui/core";

const ModalFooterButton = ({
  buttonText,
  onClick,
  isDisabled,
  id
}) => {
  return (
    <Box className="modal-footer mt-0 pt-0">
      <ActionButton
        idName={id}
        label={buttonText}
        onClick={onClick}
        className="btn-primary action-blue-button"
        disabled={isDisabled}
      />
    </Box>
  );
};

export default ModalFooterButton;
