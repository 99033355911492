export const CreateAssetGroupUrl = `api/mobopro/v1/assets/groups`;
export const GetAssetGroupsUrl = (onlyactive) => {
  return `api/mobopro/v1/assets/groups?onlyactive=${onlyactive}`;
};
export const DeleteAssetGroupUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};
export const UpdateAssetGroupUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};
export const GetAssetGroupByIdUrl = (id) => {
  return `api/mobopro/v1/assets/groups/${id}`;
};
export const CreateAssetClassUrl = `api/mobopro/v1/assets/classes`;
export const GetAssetClassesUrl = (groupId) => {
  if (groupId) {
    return `api/mobopro/v1/assets/classes?includeall=true&group=${groupId}`;
  }
  return `api/mobopro/v1/assets/classes?includeall=true`;
};
export const DeleteAssetClassUrl = (id) => {
  return `api/mobopro/v1/assets/classes/${id}`;
};
export const UpdateAssetClassUrl = (id) => {
  return `api/mobopro/v1/assets/classes/${id}`;
};
export const GetAssetClassByIdUrl = (id) => {
  return `api/mobopro/v1/assets/classes/${id}`;
};
export const GenerateSasUrl = "/api/mobopro/v1/mediafiles/sas";
export const GetOutletUserRolesUrl = "/api/mobopro/v1/outletuserroles";
export const GetOutletUserPermissionsUrl =
  "/api/mobopro/v1/outletuserpermissions";
export const GetWorkPreparationsRolesUrl =
  "/api/mobopro/v1/workpreparations/roles";
export const GetDossierStacksUrl =
  "/api/mobopro/v1/dossierstacks?isSettings=true";
export const GetInternalChatGroupsUrl =
  "/api/mobopro/v1/internalchatgroups?includenonactive=false";
export const UpdateOutletUserOutletsUrl = `/api/mobopro/v1/outletuseroutlets`;
export const UpdateOutletUserUrl = "/api/mobopro/v1/outletuser";
export const UpdateOutletUserRolesAndPermissionsUrl = `/api/mobopro/v1/outletuserrolesandpermissions`;
export const UpdateOutletUserDossierStackAccessUrl = `/api/mobopro/v1/outletuserdossierstackaccess`;
export const UpdateOutletUserChatGroupsUrl = `/api/mobopro/v1/outletuserchatgroups`;
export const SaveOutletUserUrl = "/api/mobopro/v1/outletuser";
export const SaveOutletUserImageUrl = (outletId) => {
  return `/api/mobopro/v2/outletusers/${outletId}/image`;
};
export const GetOutletUserUrl = (outletUserId) => {
  return `/api/mobopro/v1/outletuser/${outletUserId}`;
};
export const SavePinUrl = "/api/mobopro/v1/pin";
export const ResetPasswordUrl = (id) => {
  return `/api/mobopro/v1/passwordreset/${id}`;
};
export const UpdatePasswordUrl = (id) => {
  return `/api/mobopro/v1/updatepassword/${id}`;
};