export const LanguagesWithLanguageCode = [
    {Name: "Nederlands", code: "nl"},
    {Name: "Français", code: "fr"},
    {Name: "English", code: "en"},
    {Name: "Deutsch", code: "de"},
    {Name: "Czech", code: "cs"},
]

export const HttpMethods = {
    Get: "GET",
    Post: "POST",
    Put: "PUT",
    Patch: "PATCH",
    Delete: "DELETE"
}

export const Languages = [
    { key: "nl-be", name: "Nederlands (BE)" },
    { key: "nl-nl", name: "Nederlands (NL)" },
    { key: "fr-be", name: "Français" },
    { key: "en-gb", name: "English" },
    { key: "de-de", name: "Deutsch" },
    { key: "cs-cz", name: "Czech" },
];
