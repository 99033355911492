import { DisplayMessage } from "../helpers.js";
import { UpdateRequest, GetRequest } from "../requestHelpers.js";
import { HttpMethods } from "../../utils/constants";
import {
  SaveOutletUserImageUrl,
  GetOutletUserRolesUrl,
  GetOutletUserPermissionsUrl,
  GetWorkPreparationsRolesUrl,
  GetDossierStacksUrl,
  GetInternalChatGroupsUrl,
  GetOutletUserUrl,
  UpdateOutletUserOutletsUrl,
  UpdateOutletUserUrl,
  UpdateOutletUserRolesAndPermissionsUrl,
  UpdateOutletUserDossierStackAccessUrl,
  UpdateOutletUserChatGroupsUrl,
  SaveOutletUserUrl,
  SavePinUrl,
  ResetPasswordUrl,
  UpdatePasswordUrl
} from "../../constants/apiUrls.js";

export const fetchPermissions = async () => {
  const response = await GetRequest(GetOutletUserPermissionsUrl);
  const result = await response.json();

  if (!response.ok) {
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }

  const roles = result.map((item) => ({
    id: item.id,
    description: item.description,
  }));

  return roles.sort((a, b) => a.description.localeCompare(b.description));
};

export const fetchWorkPreparationRoles = async () => {
  const response = await GetRequest(GetWorkPreparationsRolesUrl);
  const result = await response.json();

  if (!response.ok) {
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }

  const workPreparationRoles = result.map((item) => ({
    id: item.id,
    description: item.description,
    isActive: item.isActive,
  }));

  const workPreparationRolesActive = workPreparationRoles.filter(
    (x) => x.isActive
  );

  return workPreparationRolesActive.sort((a, b) =>
    a.description.localeCompare(b.description)
  );
};

export const fetchRoles = async () => {
  const response = await GetRequest(GetOutletUserRolesUrl);
  const result = await response.json();

  if (!response.ok) {
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }

  const roles = result.map((item) => ({
    id: item.id,
    description: item.description,
  }));

  return roles.sort((a, b) => a.description.localeCompare(b.description));
};

export const fetchDossierStacks = async () => {
  const response = await GetRequest(GetDossierStacksUrl);
  const result = await response.json();

  if (!response.ok) {
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }

  return result;
};

export const fetchInternalChatGroups = async () => {
  const response = await GetRequest(GetInternalChatGroupsUrl);
  const result = await response.json();

  if (!response.ok) {
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }

  return result;
};

export const fetchOutletUserData = async (outletUserId) => {
  const response = await GetRequest(GetOutletUserUrl(outletUserId));
  const result = await response.json();

  if (!response.ok) {
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }
  return result;
};

export const updateOutletUserDossierStacks = async (outletUserId, stackIds) => {
  const body = JSON.stringify({ id: outletUserId, stackIds });

  const response = await UpdateRequest(
    UpdateOutletUserDossierStackAccessUrl,
    body,
    HttpMethods.Put
  );

  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }

  return true;
};

export const updateOutletUserChatGroups = async (
  outletUserId,
  internalChatGroups
) => {
  const body = JSON.stringify({
    outletUserId,
    internalChatGroups,
  });

  const response = await UpdateRequest(
    UpdateOutletUserChatGroupsUrl,
    body,
    HttpMethods.Put
  );

  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }

  return true;
};

export const updateOutletUserRolesAndPermissions = async (
  outletUserId,
  roleIDs,
  permissionIDs,
  successMessage
) => {
  const body = JSON.stringify({ id: outletUserId, roleIDs, permissionIDs });

  const response = await UpdateRequest(
    UpdateOutletUserRolesAndPermissionsUrl,
    body,
    HttpMethods.Put
  );

  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return;
  }

  DisplayMessage(successMessage, "success");
};

export const createOutletUser = async (jsonUserData) => {
  const response = await UpdateRequest(
    SaveOutletUserUrl,
    jsonUserData,
    HttpMethods.Post
  );

  const result = await response.json();

  if (!response.ok) {
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return null;
  }

  return result;
};

export const updateOutletUser = async (json) => {
  const response = await UpdateRequest(
    UpdateOutletUserUrl,
    json,
    HttpMethods.Put
  );

  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return false;
  }

  return true;
};

export const updateOutletUserOutlets = async (
  outletUserId,
  mainMoboOutletId,
  moboOutletIds,
  successMessage
) => {
  const body = JSON.stringify({
    id: outletUserId,
    mainMoboOutletId,
    moboOutletIds,
  });

  const response = await UpdateRequest(
    UpdateOutletUserOutletsUrl,
    body,
    HttpMethods.Put
  );

  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return;
  }

  DisplayMessage(successMessage, "success");
};

export const updateOutletUserProfileImage = async (
  outletId,
  blobRelativePath
) => {
  const file = { blobRelativePath, type: 1 };

  const body = JSON.stringify({ file });
  const url = SaveOutletUserImageUrl(outletId);

  const response = await UpdateRequest(url, body, HttpMethods.Put);

  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return undefined;
  }

  return true;
};

export const saveNewPin = async (body) => {
  const response = await UpdateRequest(SavePinUrl, body, HttpMethods.Put);
  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return false;
  }
  return true;
};

export const resetPassword = async (id) => {
  const response = await UpdateRequest(
    ResetPasswordUrl(id),
    null,
    HttpMethods.Put,
  );

  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return false;
  }
  return true;
};

export const updatePassword = async (outletUsersId, body) => {
  const response = await UpdateRequest(UpdatePasswordUrl(outletUsersId), body, HttpMethods.Put);
  if (!response.ok) {
    const result = await response.json();
    for (const error of result.errors) {
      DisplayMessage(error, "error");
    }
    return false;
  }
  return true;
};