import React from "react";
import Form from "react-bootstrap/Form";
import { InputLabel, Link, FormHelperText } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { SelectTheme } from "../../../../../utils/utils.js";

const CustomFormGroupWithSelect = ({
  selectText,
  valueName,
  handleOnClick,
  inputLabel,
  isMandatory,
  idName,
  linkClassName = "col-4 text-right float-right pointer",
  disabled = false,
}) => {
  return (
    <Form.Group className="d-flex">
      <div className="col-12 ml-0 p-0 ">
        <hr className="MuiDivider-root mb-2" />
        <ThemeProvider theme={SelectTheme}>
          <InputLabel className="col-6 text-left float-left">
            <b>
              {inputLabel}
              {isMandatory && <em className="ml-1 text-danger">*</em>}
            </b>
          </InputLabel>
        </ThemeProvider>
        {!disabled && (
          <Link
            className={linkClassName}
            variant="body2"
            id={idName}
            onClick={() => handleOnClick(true)}
          >
            {selectText}
          </Link>
        )}
        <FormHelperText style={{ clear: "both" }}>{valueName}</FormHelperText>
      </div>
    </Form.Group>
  );
};

export default CustomFormGroupWithSelect;
