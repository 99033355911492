import { HttpMethods } from "../utils/constants";
import { GenerateSasUrl } from "../constants/apiUrls";
import { DisplayMessage } from "./helpers";
import { GetUpdateHeaders } from "./requestHelpers";

export async function GenerateSAS(data) {
  const response = await fetch(GenerateSasUrl, {
    method: HttpMethods.Post,
    headers: GetUpdateHeaders(),
    body: data,
  });

  var result = await response.json();
  if (!response.ok) {
    for (var i = 0; i < result.errors.length; i++) {
      DisplayMessage(result.errors[i], "error");
    }
    return undefined;
  }
  return result;
}

export const CheckIfSasIsValid = (sas) => {
  if (sas === undefined || sas.length === 0) return false;

  var fileSas = sas[0];
  if (!fileSas.blobRelativePath || fileSas.blobRelativePath.trim().length === 0)
    return false;

  return true;
};

export const UploadFileInStorage = async (
  file,
  sas,
  contentType,
  errorOnUpload
) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = async (event) => {
      if (event.target.readyState === FileReader.DONE) {
        const requestData = new Uint8Array(event.target.result);
        const url = `${sas.blobUri}${sas.token}`;
        var response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": `${contentType}`,
            "x-ms-blob-type": "BlockBlob",
          },
          body: requestData,
        });

        if (!response.ok) {
          const result = await response.json();
          result.errors.forEach((error) => {
            DisplayMessage(error, "error");
          });
          resolve(undefined);
        } else {
          resolve(sas.blobRelativePath);
        }
      }
    };
    reader.onerror = () => {
      DisplayMessage(errorOnUpload, "error");
      resolve(undefined);
    };
  });
};
