import { Box, Paper, Dialog, CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import Form from "react-bootstrap/Form";
import "toastr/build/toastr.min.css";
import {
  createOutletUser,
  fetchDossierStacks,
  fetchInternalChatGroups,
  fetchOutletUserData,
  updateOutletUser,
  updateOutletUserChatGroups,
  updateOutletUserDossierStacks,
  updateOutletUserOutlets,
  updateOutletUserProfileImage,
  updateOutletUserRolesAndPermissions,
} from "../../../services/OutletUsers/outletUserService.js";
import {
  ValidateEmail,
  ValidateInternalDialogName,
  ValidatePassword,
  ValidatePhone,
  ValidatePin,
  DisplayMessage,
  GenerateInitials,
  GetFileExtension,
  Uuidv4,
} from "../../../services/helpers.js";
import {
  CheckIfSasIsValid,
  GenerateSAS,
  UploadFileInStorage,
} from "../../../services/uploadHelpers.js";
import DelayedRedirect from "../../../utils/DelayedRedirect.js";
import { Transition } from "../../../utils/ModalHelpers.js";
import { Languages } from "../../../utils/constants.js";
import ProfileImage from "./components/ProfileImage";
import WorkPreparationSelection from "./components/WorkPreparationSelection.js";
import CustomSelectionSection from "./components/common/CustomSelectionSection.js";
import ChatGroupsRodal from "./components/rodals/ChatGroupsRodal.js";
import DossierStacksRodal from "./components/rodals/DossierStacksRodal.js";
import MoboOutletsRodal from "./components/rodals/MoboOutletsRodal.js";
import PermissionsRodal from "./components/rodals/PermissionsRodal.js";
import RolesRodal from "./components/rodals/RolesRodal.js";
import ResetSection from "./components/ResetSection.js";
import CreatedByInfo from "./../../Common/Footers/CreatedByInfo";
import AppBarComponent from "./../../Common/Headers/AppBarComponent";
import TextFieldComponent from "./../../Common/Inputs/TextFieldComponent";
import DropdownComponent from "./../../Common/Dropdowns/DropdownComponent";
import NumericFieldComponent from "./../../Common/Inputs/NumericFieldComponent";

const OutletUser = (props) => {
  const { resources, accessibleMoboOutlets, outletUserId } = props;

  const isEditMode = useMemo(
    () => outletUserId && outletUserId > 0,
    [outletUserId]
  );

  const [outletUserEmail, setOutletUserEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [initials, setInitials] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [phone, setPhone] = useState(null);
  const [internalPhoneNumber, setInternalPhoneNumber] = useState(null);
  const [dmsReference, setDmsReference] = useState("");
  const [dmsReference2, setDmsReference2] = useState("");
  const [language, setLanguage] = useState("nl-be");
  const [internalDialogName, setInternalDialogName] = useState("");
  const [requestInProgress, setRequestInProgress] = useState(true);
  const [gender, setGender] = useState("m");
  const [clockReference, setClockReference] = useState("");
  const [planningReference, setPlanningReference] = useState("");
  const [outletUserWasCreated, setOutletUserWasCreated] = useState(false);
  const [workPreparationRoleId, setWorkPreparationRoleId] = useState(0);
  const [isDeleted, setIsDeleted] = useState(false);
  const [filterChatGroups, setFilterChatGroups] = useState([]);
  const [chatGroups, setChatGroups] = useState([]);
  const [filterDossierStacks, setFilterDossierStacks] = useState([]);
  const [dossierStacks, setDossierStacks] = useState([]);

  const [loadingChatGroups, setLoadingChatGroups] = useState(true);
  const [loadingDossierStacks, setLoadingDossierStacks] = useState(true);
  const [loadingOutletUser, setLoadingOutletUser] = useState(isEditMode);
  const [showRodalOutlets, setShowRodalOutlets] = useState(false);
  const [showRodalRoles, setShowRodalRoles] = useState(false);
  const [showRodalPermissions, setShowRodalPermissions] = useState(false);
  const [showRodalChatGroups, setShowRodalChatGroups] = useState(false);
  const [showRodalDossierStacks, setShowRodalDossierStacks] = useState(false);

  const [selectedOutletsData, setSelectedOutletsData] = useState({
    savedMainOutlet: 0,
    selectedOutlets: [],
    selectedOutletsName: "",
    outletsWasUpdated: false,
  });

  const [selectedRolesData, setSelectedRolesData] = useState({
    selectedRoles: [],
    selectedRolesName: "",
    rolesWasUpdated: false,
  });

  const [selectedPermissionsData, setSelectedPermissionsData] = useState({
    selectedPermissions: [],
    selectedPermissionsName: "",
    permissionsWasUpdated: false,
  });

  const [selectedChatGroupsData, setSelectedChatGroupsData] = useState({
    selectedChatGroups: [],
    selectedChatGroupsName: "",
    chatGroupsWasUpdated: false,
  });

  const [selectedDossierStacksData, setSelectedDossierStacksData] = useState({
    selectedDossierStacks: [],
    selectedDossierStacksName: "",
    dossierStacksWasUpdated: false,
  });

  const [imageProfileData, setImageProfileData] = useState(null);
  const [file, setFile] = useState(null);
  const [state, setState] = useState({
    id: 0,
    redirect: null,
    open: true,
  });

  const genderValues = [
    { key: "m", name: resources["Male"] },
    { key: "f", name: resources["Female"] },
  ];

  const validateName = (name) => name && name.trim().length > 0;

  const checkIfRequestIsValid = useCallback(
    (displayErrorMessage) => {
      let isValidRequest = true;

      const isValidEmail = ValidateEmail(outletUserEmail);
      if (!isValidEmail) {
        if (displayErrorMessage) {
          isValidRequest = false;
          DisplayMessage(resources["InvalidEmail"], "error");
        } else return false;
      }

      if (!validateName(firstName)) {
        if (displayErrorMessage) {
          isValidRequest = false;
          DisplayMessage(resources["InvalidFirstName"], "error");
        } else return false;
      }

      if (!validateName(lastName)) {
        if (displayErrorMessage) {
          isValidRequest = false;
          DisplayMessage(resources["InvalidLastName"], "error");
        } else return false;
      }

      if (!isEditMode) {
        if (pin !== null) {
          if (!ValidatePin(pin)) {
            if (displayErrorMessage) {
              isValidRequest = false;
              DisplayMessage(resources["InvalidPin"], "error");
            } else return false;
          }
        }

        if (password) {
          const isPasswordValid = ValidatePassword(password);
          if (!isPasswordValid) {
            if (displayErrorMessage) {
              isValidRequest = false;
              displayErrorMessage(resources["InvalidPassword"], "error");
            } else return false;
          }
        }
      }

      if (phone !== null) {
        const isPhoneValid = ValidatePhone(phone);
        if (!isPhoneValid) {
          if (displayErrorMessage) {
            isValidRequest = false;
            DisplayMessage(resources["InvalidPhone"], "error");
          } else return false;
        }
      }
      if (internalPhoneNumber !== null) {
        const isPhoneValid = ValidatePhone(internalPhoneNumber);
        if (!isPhoneValid) {
          if (displayErrorMessage) {
            isValidRequest = false;
            DisplayMessage(resources["InvalidInternalPhoneNumber"], "error");
          } else return false;
        }
      }

      if (internalDialogName !== null) {
        const isInternaDialogNameValid =
          ValidateInternalDialogName(internalDialogName);
        if (!isInternaDialogNameValid) {
          if (displayErrorMessage) {
            isValidRequest = false;
            DisplayMessage(resources["InvalidChatName"], "error");
          } else return false;
        }
      }

      if (
        selectedOutletsData.savedMainOutlet < 1 ||
        selectedRolesData.selectedRoles.length < 1
      ) {
        if (displayErrorMessage) {
          isValidRequest = false;
          DisplayMessage(resources["InvalidOutletsOrRoles"], "error");
        } else return false;
      }
      return isValidRequest;
    },
    [
      outletUserEmail,
      firstName,
      lastName,
      isEditMode,
      pin,
      phone,
      internalPhoneNumber,
      internalDialogName,
      selectedOutletsData.savedMainOutlet,
      selectedRolesData.selectedRoles.length,
      resources,
      password,
    ]
  );

  const processItems = (items, descriptionField = "description") => {
    const selectedIds = [];
    const selectedNames = [];

    items.forEach((item) => {
      if (item.linked === true) {
        selectedIds.push(item.id);
        selectedNames.push(item[descriptionField]);
      }
    });

    return {
      selectedIds,
      selectedNames: selectedNames.join(", "),
    };
  };

  const handleClose = () => {
    setState({
      id: 0,
      redirect: "/outletusers",
      open: false,
    });
  };

  const getData = useCallback(async (outletUserId) => {
    if (!outletUserId || outletUserId < 1) return;

    try {
      const result = await fetchOutletUserData(outletUserId);
      if (!result) {
        handleClose();
        return;
      }

      const selectedOutletsData = processItems(result.moboOutlets);
      const selectedRolesData = processItems(result.roles);
      const selectedPermissionsData = processItems(result.permissions);
      const selectedChatGroups = result.chatGroups.map((item) => item.id);
      const selectedChatGroupsNames = result.chatGroups
        .map((item) => item.description)
        .join(", ");
      const selectedDossierStacks = result.dossierStacks.map(
        (item) => item.stackId
      );
      const selectedDossierStacksNames = result.dossierStacks
        .map((item) => item.description)
        .join(", ");

      setFirstName(result.firstName);
      setLastName(result.lastName);
      setOutletUserEmail(result.username);
      setDmsReference(result.dmsReference);
      setDmsReference2(result.dmsReference2);
      setClockReference(result.clockReference);
      setPlanningReference(result.planningReference);
      setInitials(result.initials);
      setPhone(result.phone ?? null);
      setInternalPhoneNumber(result.internalPhoneNumber ?? null);
      setLanguage(result.culture ?? "nl-be");
      setGender(result.gender);
      setInternalDialogName(result.internalDialogName);
      setWorkPreparationRoleId(result.workPreparationRole?.id);
      setIsDeleted(result.isDeleted);
      setSelectedOutletsData({
        outletsWasUpdated: false,
        selectedOutlets: selectedOutletsData.selectedIds,
        selectedOutletsName: selectedOutletsData.selectedNames,
        savedMainOutlet:
          result.moboOutlets.find((item) => item.isMainOutlet)?.id || 0,
      });
      setSelectedRolesData({
        rolesWasUpdated: false,
        selectedRoles: selectedRolesData.selectedIds,
        selectedRolesName: selectedRolesData.selectedNames,
      });
      setSelectedPermissionsData({
        permissionsWasUpdated: false,
        selectedPermissions: selectedPermissionsData.selectedIds,
        selectedPermissionsName: selectedPermissionsData.selectedNames,
      });
      setSelectedDossierStacksData({
        dossierStacksWasUpdated: false,
        selectedDossierStacks,
        selectedDossierStacksName: selectedDossierStacksNames,
      });
      setSelectedChatGroupsData({
        chatGroupsWasUpdated: false,
        selectedChatGroups,
        selectedChatGroupsName: selectedChatGroupsNames,
      });
      setImageProfileData({
        createdOn: result.createdOn,
        createdBy: result.createdBy,
        profileImageUri: result.avatar,
      });
    } catch (error) {
      error.message.split(", ").forEach((msg) => DisplayMessage(msg, "error"));
    } finally {
      setLoadingOutletUser(false); // Ensure loading state is cleared
    }
    return;
  }, []);

  const outletDescriptions = useMemo(() => {
    return accessibleMoboOutlets.reduce((acc, outlet) => {
      acc[outlet.id] = outlet.description;
      return acc;
    }, {});
  }, [accessibleMoboOutlets]);

  const getFilterDossierStacks = () => {
    setLoadingDossierStacks(true);
    const selectedOutlets = selectedOutletsData.selectedOutlets;

    if (selectedOutlets.length < 1) return;

    const dossierStacksSorted = dossierStacks
      .filter(
        (item) =>
          item.outletId === null || selectedOutlets.includes(item.outletId)
      )
      .map((item) => ({
        id: item.id,
        description: item.description,
        fullDescription: `${item.description}${
          item.outletId ? ` (${outletDescriptions[item.outletId]})` : ""
        }`,
      }))
      .sort((a, b) => a.fullDescription.localeCompare(b.fullDescription));

    setFilterDossierStacks(dossierStacksSorted);
    setLoadingDossierStacks(false);
  };

  const getFilterChatGroups = () => {
    setLoadingChatGroups(true);
    const selectedOutlets = selectedOutletsData.selectedOutlets;

    if (selectedOutlets.length < 1) return;
    const chatGroupsList = chatGroups
      .filter(
        (item) =>
          item.moboOutletID === null ||
          selectedOutlets.includes(item.moboOutletID)
      )
      .map((item) => ({
        id: item.id,
        description: item.description,
      }))
      .sort((a, b) => a.description.localeCompare(b.description));

    setFilterChatGroups(chatGroupsList);
    setLoadingChatGroups(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getData(outletUserId);
      const [dossierData, chatGroupData] = await Promise.all([
        fetchDossierStacks(),
        fetchInternalChatGroups(),
      ]);
      setDossierStacks(dossierData || []);
      setChatGroups(chatGroupData || []);
    };
    fetchData();
  }, [getData, outletUserId]);

  useEffect(() => {
    const requestIsValid = checkIfRequestIsValid(false);
    setRequestInProgress(!requestIsValid);
  }, [
    outletUserEmail,
    firstName,
    lastName,
    password,
    pin,
    phone,
    internalPhoneNumber,
    internalDialogName,
    selectedOutletsData.savedMainOutlet,
    selectedRolesData.selectedRoles,
    checkIfRequestIsValid,
  ]);

  const generateUserData = () => {
    const baseData = {
      username: outletUserEmail,
      firstName: firstName,
      lastName: lastName,
      initials:
        initials === null || initials === ""
          ? GenerateInitials(`${firstName?.trim()} ${lastName?.trim()}`)
          : initials,
      culture: language,
      gender: gender,
      phone: phone === "" ? null : phone,
      internalPhoneNumber:
        internalPhoneNumber === "" ? null : internalPhoneNumber,
      dmsReference: dmsReference,
      dmsReference2: dmsReference2,
      clockReference: clockReference === "" ? null : clockReference,
      planningReference: planningReference === "" ? null : planningReference,
      internalDialogName: internalDialogName === "" ? null : internalDialogName,
      workPreparationRoleId:
        workPreparationRoleId > 0 ? workPreparationRoleId : null,
      active: true,
    };

    if (isEditMode) {
      return JSON.stringify({
        ...baseData,
        id: outletUserId,
      });
    }

    return JSON.stringify({
      ...baseData,
      password: password === "" ? null : password,
      pin: pin === "" ? null : pin,
    });
  };

  //(1) : input of personal data
  const saveOutletUser = async () => {
    setRequestInProgress(true);

    if (!checkIfRequestIsValid(true)) return;

    let newOutletUserId = 0;

    try {
      const jsonUserData = generateUserData();

      if (isEditMode) {
        const result = await updateOutletUser(jsonUserData);
        if (result) {
          newOutletUserId = outletUserId;
          DisplayMessage(resources["OutletUserWasSaved"], "success");
        }
      } else {
        const result = await createOutletUser(jsonUserData);
        if (result) {
          newOutletUserId = result.outletUserId;
          DisplayMessage(resources["NewOutletUserWasSaved"], "success");
        } else {
          return;
        }
      }

      if (newOutletUserId > 0) {
        // Save linked outlets first, and after that linked roles, permissions, chat groups and dossier stacks
        await handleSaveOutletsWithPermissionsAndChatGroupsAndDossierStacks(
          newOutletUserId
        );
        // Save outlet user profile image
        await handleSaveOutletUserProfileImage(newOutletUserId);
        setOutletUserWasCreated(true);
      }
      setState((prevState) => ({
        ...prevState,
        redirect: "/outletusers",
      }));
    } catch (error) {
      error.message.split(", ").forEach((msg) => DisplayMessage(msg, "error"));
    }
  };

  const uploadFile = async (file, body, error) => {
    const sas = await GenerateSAS(body);
    if (!CheckIfSasIsValid(sas)) {
      DisplayMessage(resources["ErrorUploadingFileToStorage"], "error");
      return null;
    }
    const blobPath = await UploadFileInStorage(
      file,
      sas[0],
      "image/png",
      error
    );
    return blobPath;
  };

  const handleSaveOutletUserProfileImage = async (outletId) => {
    if (outletId < 1 || !file) return;

    const fileExtention = GetFileExtension(file.name);
    const sasRequest = JSON.stringify({
      minutes: 5,
      entityType: "outletuser",
      entityValue: outletId,
      fileNames: [`${Uuidv4()}.${fileExtention}`],
    });

    const blobPath = await uploadFile(
      file,
      sasRequest,
      resources["ErrorOnLoadingFile"]
    );

    if (!blobPath) {
      setRequestInProgress(false);
      return;
    }

    const result = await updateOutletUserProfileImage(outletId, blobPath);
    if (result) {
      DisplayMessage(resources["OutletUserImageWasSaved"], "success");
    }
  };

  const handleSaveOutletsWithPermissionsAndChatGroupsAndDossierStacks = async (
    outletUserId
  ) => {
    if (outletUserId < 1 || selectedOutletsData.savedMainOutlet < 1) return;

    try {
      if (selectedOutletsData.outletsWasUpdated === true) {
        await updateOutletUserOutlets(
          outletUserId,
          selectedOutletsData.savedMainOutlet,
          selectedOutletsData.selectedOutlets,
          resources["OutletUserOutletsWasSaved"]
        );
      }
      if (
        selectedRolesData.rolesWasUpdated === true ||
        selectedPermissionsData.permissionsWasUpdated === true
      ) {
        await handleSaveOutletUserRolesAndPermissions(outletUserId);
      }
      if (selectedChatGroupsData.chatGroupsWasUpdated === true) {
        await handleSaveOutletUserChatGroups(outletUserId);
      }
      if (selectedDossierStacksData.dossierStacksWasUpdated === true) {
        await handleSaveOutletUserDossierStacks(outletUserId);
      }
    } catch (error) {
      error.message.split(", ").forEach((msg) => DisplayMessage(msg, "error"));
    }
  };

  const handleSaveOutletUserRolesAndPermissions = async (outletUserId) => {
    if (outletUserId < 1 || selectedRolesData.selectedRoles.length < 1) return;
    try {
      await updateOutletUserRolesAndPermissions(
        outletUserId,
        selectedRolesData.selectedRoles,
        selectedPermissionsData.selectedPermissions,
        resources["OutletUserRolesAndPermissionsWasSaved"]
      );
    } catch (error) {
      error.message.split(", ").forEach((msg) => DisplayMessage(msg, "error"));
    }
  };

  const handleSaveOutletUserChatGroups = async (outletUserId) => {
    if (outletUserId < 1) return;

    try {
      await updateOutletUserChatGroups(
        outletUserId,
        selectedChatGroupsData.selectedChatGroups
      );
      DisplayMessage(resources["OutletUserChatGroupsWasSaved"], "success");
    } catch (error) {
      error.message.split(", ").forEach((msg) => DisplayMessage(msg, "error"));
    }
  };

  const handleSaveOutletUserDossierStacks = async (outletUserId) => {
    if (outletUserId < 1) return;
    try {
      await updateOutletUserDossierStacks(
        outletUserId,
        selectedDossierStacksData.selectedDossierStacks
      );
      DisplayMessage(
        resources["OutletUserDossierStackAccessWasSaved"],
        "success"
      );
    } catch (error) {
      error.message.split(", ").forEach((msg) => DisplayMessage(msg, "error"));
    }
  };

  //dropzone
  const handleFileChange = (files) => {
    const firstfile = files.length > 0 ? files[0] : null;
    setFile(firstfile);
  };

  const removeProfileImage = () => {
    if (imageProfileData) {
      setImageProfileData((prevState) => ({
        ...prevState,
        profileImageUri: "",
      }));
      setFile(null);
    }
  };

  // Mobo Outlets
  const areAllInitialOutletsPresent = (previousSelectedOutlets, selectedOutlets) => {
    if (previousSelectedOutlets.length === 0) return false;

    const selectedOutletsSet = new Set(selectedOutlets);
    return previousSelectedOutlets.every((outlet) => selectedOutletsSet.has(outlet));
  };

  const handleConfirmSelectedOutlets = (data) => {
    if (data == null) return;

    var previousSelectedOutlets = selectedOutletsData.selectedOutlets;

    if (data.selectedOutletsChecked.length > 0) {
      getFilterChatGroups();
      getFilterDossierStacks();

      setShowRodalOutlets(false);
      setSelectedOutletsData({
        savedMainOutlet: data.selectMainOutlet,
        selectedOutlets: data.selectedOutletsChecked,
        selectedOutletsName: data.selectedOutletsCheckedName,
        outletsWasUpdated: true,
      });

      setRequestInProgress(!checkIfRequestIsValid(false));
      if (!areAllInitialOutletsPresent(previousSelectedOutlets, data.selectedOutletsChecked)) {
        const numberOfChatGroups =
          selectedChatGroupsData.selectedChatGroups.length;
        const numberOfDossierStacks =
          selectedDossierStacksData.selectedDossierStacks.length;

        if (numberOfChatGroups > 0) {
          setSelectedChatGroupsData({
            selectedChatGroups: [],
            selectedChatGroupsName: "",
            chatGroupsWasUpdated: true,
          });
          DisplayMessage(resources["SelectedChatGoupsWasReset"], "info");
        }

        if (numberOfDossierStacks > 0) {
          setSelectedDossierStacksData({
            selectedDossierStacks: [],
            selectedDossierStacksName: "",
            dossierStacksWasUpdated: true,
          });
          DisplayMessage(resources["SelectedDossierStacksWasReset"], "info");
        }
      }
    }
  };

  //Roles
  const handleConfirmSelectedRoles = (
    selectedRolesChecked,
    selectedRolesCheckedName
  ) => {
    if (selectedRolesChecked.length > 0) {
      setSelectedRolesData({
        selectedRoles: selectedRolesChecked,
        selectedRolesName: selectedRolesCheckedName,
        rolesWasUpdated: true,
      });
      setShowRodalRoles(false);
      setRequestInProgress(!checkIfRequestIsValid(false));
    }
  };

  //Permissions
  const handleConfirmSelectedPermissions = (
    selectedPermissionsChecked,
    selectedPermissionsCheckedName
  ) => {
    if (selectedPermissionsChecked.length >= 0) {
      setSelectedPermissionsData({
        selectedPermissions: selectedPermissionsChecked,
        selectedPermissionsName: selectedPermissionsCheckedName,
        permissionsWasUpdated: true,
      });
    }
    setShowRodalPermissions(false);
  };

  //ChatGroups
  const handleShowRodalChatGroups = () => {
    if (selectedOutletsData.selectedOutlets.length > 0) {
      getFilterChatGroups(true);
    }
    setShowRodalChatGroups(true);
  };

  const handleConfirmSelectedChatGroups = (
    selectedChatGroupsChecked,
    selectedChatGroupsCheckedName
  ) => {
    setSelectedChatGroupsData({
      selectedChatGroups: selectedChatGroupsChecked,
      selectedChatGroupsName: selectedChatGroupsCheckedName,
      chatGroupsWasUpdated: true,
    });
    setShowRodalChatGroups(false);
  };

  //DossierStacks
  const handleShowRodalDossierStacks = () => {
    if (selectedOutletsData.selectedOutlets.length > 0) {
      getFilterDossierStacks();
    }
    setShowRodalDossierStacks(true);
  };

  const handleConfirmSelectedDossierStacks = (
    selectedDossierStacksChecked,
    selectedDossierStacksCheckedName
  ) => {
    setSelectedDossierStacksData({
      selectedDossierStacks: selectedDossierStacksChecked,
      selectedDossierStacksName: selectedDossierStacksCheckedName,
      dossierStacksWasUpdated: true,
    });
    setShowRodalDossierStacks(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    saveOutletUser();
  };

  const renderForm = () => {
    const displayChatGroups =
      "col-4 text-right float-right " +
      (selectedOutletsData.savedMainOutlet > 0
        ? " pointer "
        : "  disabled-hover text-secondary");
    const displayDossierStacks =
      "col-4 text-right float-right " +
      (selectedOutletsData.savedMainOutlet > 0
        ? " pointer "
        : "  disabled-hover text-secondary");

    return (
      <Box>
        <Paper elevation={3} className="p-4 mt-3 mb-3">
          <Form
            onSubmit={handleSubmit}
            autoComplete="off"
            className={outletUserId ? "" : "pt-3"}
          >
            {outletUserId && (
              <ResetSection
                resources={resources}
                outletUserId={outletUserId}
                disabled={isDeleted}
              />
            )}
            <Box className="d-flex col-12">
              <Box className="col-3" />
              <Box className="col-6 p-0">
                <TextFieldComponent
                  value={outletUserEmail}
                  handleChange={(e) => setOutletUserEmail(e.target.value)}
                  labelText={resources["Email"]}
                  id="outletuser-email"
                  maxLength={200}
                  helperText={resources["InputOfEmailIsMandatory"]}
                  isValid={ValidateEmail(outletUserEmail)}
                  disabled={isDeleted}
                />
                <TextFieldComponent
                  value={firstName}
                  handleChange={(e) => {
                    setFirstName(e.target.value);
                    setInitials(null);
                  }}
                  isValid={validateName(firstName)}
                  id="outletuser-firstname"
                  labelText={resources["FirstName"]}
                  helperText={resources["InputOfFirstNameIsMandatory"]}
                  maxLength={100}
                  disabled={isDeleted}
                />
                <TextFieldComponent
                  isValid={validateName(lastName)}
                  id="outletuser-lastname"
                  value={lastName}
                  labelText={resources["LastName"]}
                  helperText={resources["InputOfLastNameIsMandatory"]}
                  name="lastName"
                  handleChange={(e) => {
                    setLastName(e.target.value);
                    setInitials(null);
                  }}
                  maxLength={100}
                  disabled={isDeleted}
                />
                <Box className="col-6 p-0">
                  <TextFieldComponent
                    isValid={false}
                    id="outletuser-initials"
                    value={initials}
                    labelText={resources["Initials"]}
                    variant="outlined"
                    name="initials"
                    handleChange={(e) => setInitials(e.target.value)}
                    maxLength={10}
                    disabled={isDeleted}
                  />
                  {!isEditMode && (
                    <TextFieldComponent
                      isValid={false}
                      id="outletuser-password"
                      value={password}
                      labelText={resources["Password"]}
                      name="password"
                      handleChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      maxLength={14}
                      autoComplete="off"
                      type="password"
                      helperText={
                        !password || ValidatePassword(password)
                          ? ""
                          : resources["InvalidPassword"]
                      }
                      tooltipText={resources["PasswordValidationRules"]}
                      disabled={isDeleted}
                    />
                  )}
                </Box>
                <DropdownComponent
                  handleChange={(e) =>
                    setLanguage(e.target.value === "" ? null : e.target.value)
                  }
                  value={language}
                  labelText={resources["Language"]}
                  id="outletusers-language"
                  options={Languages}
                  disabled={isDeleted}
                />
                <DropdownComponent
                  handleChange={(e) =>
                    setGender(e.target.value === "" ? null : e.target.value)
                  }
                  value={gender}
                  labelText={resources["Gender"]}
                  id="outletusers-gender"
                  options={genderValues}
                  disabled={isDeleted}
                />
                <Box className="col-6 p-0">
                  <TextFieldComponent
                    isValid={false}
                    id="outletuser-phone"
                    value={phone}
                    labelText={resources["Phone"]}
                    handleChange={(e) => setPhone(e.target.value)}
                    maxLength={20}
                    helperText={
                      ValidatePhone(phone) ? "" : resources["InvalidPhone"]
                    }
                    disabled={isDeleted}
                  />
                  <TextFieldComponent
                    isValid={false}
                    id="outletuser-internalphone"
                    value={internalPhoneNumber}
                    labelText={resources["InternalPhoneNumber"]}
                    handleChange={(e) => setInternalPhoneNumber(e.target.value)}
                    maxLength={20}
                    helperText={
                      ValidatePhone(internalPhoneNumber)
                        ? ""
                        : resources["InvalidInternalPhoneNumber"]
                    }
                    disabled={isDeleted}
                  />
                  <TextFieldComponent
                    isValid={false}
                    id="outletuser-dms-reference"
                    value={dmsReference}
                    labelText={resources["DmsReference"]}
                    name="dmsReference"
                    handleChange={(e) => setDmsReference(e.target.value)}
                    maxLength={20}
                    disabled={isDeleted}
                  />
                  <TextFieldComponent
                    isValid={false}
                    id="outletuser-dms-reference-2"
                    value={dmsReference2}
                    labelText={resources["DmsReference2"]}
                    name="dmsReference2"
                    handleChange={(e) => setDmsReference2(e.target.value)}
                    maxLength={20}
                    disabled={isDeleted}
                  />
                  <TextFieldComponent
                    isValid={false}
                    id="outletuser-clock-reference"
                    value={clockReference}
                    labelText={resources["ClockReference"]}
                    name="clockReference"
                    handleChange={(e) => setClockReference(e.target.value)}
                    maxLength={20}
                    disabled={isDeleted}
                  />
                  <TextFieldComponent
                    isValid={false}
                    id="outletuser-planning-reference"
                    value={planningReference}
                    labelText={resources["PlanningReference"]}
                    name="planningReference"
                    handleChange={(e) => setPlanningReference(e.target.value)}
                    maxLength={20}
                    disabled={isDeleted}
                  />
                  <TextFieldComponent
                    isValid={false}
                    id="outletuser-chatname"
                    value={internalDialogName}
                    labelText={resources["ChatName"]}
                    name="internalDialogName"
                    handleChange={(e) =>
                      setInternalDialogName(e.target.value?.trim())
                    }
                    maxLength={20}
                    helperText={
                      ValidateInternalDialogName(internalDialogName)
                        ? ""
                        : resources["InvalidChatName"]
                    }
                    disabled={isDeleted}
                  />
                  {!isEditMode && (
                    <NumericFieldComponent
                      id="outletuser-pin"
                      value={pin}
                      isValid={ValidatePin(pin)}
                      labelText={resources["Pin"]}
                      maxLength={9999}
                      minLength={1000}
                      handleChange={(e) =>
                        setPin(
                          e.target.value === "" ? "" : parseInt(e.target.value)
                        )
                      }
                      helperText={resources["InvalidPin"]}
                      disabled={isDeleted}
                    />
                  )}
                </Box>
                <WorkPreparationSelection
                  handleChange={(e) =>
                    setWorkPreparationRoleId(parseInt(e.target.value))
                  }
                  value={workPreparationRoleId}
                  optionSelectText={resources["Select"]}
                  inputLabel={resources["WorkPreparationRole"]}
                  disabled={isDeleted}
                />
              </Box>
              <Box className="col-3" />
            </Box>
            <Box className="d-flex col-12">
              <Box className="col-3" />
              <Box className="col-9 p-0" display="flex" flexDirection="column">
                <ProfileImage
                  imageProfileData={imageProfileData}
                  removeProfileImage={removeProfileImage}
                  handleFileChange={handleFileChange}
                  file={file}
                  resources={resources}
                  disabled={isDeleted}
                />
              </Box>
            </Box>
            <Box className="d-flex form-group col-12">
              <Box className="col-3" />
              <Box className="col-6 p-0" display="flex" flexDirection="column">
                <CustomSelectionSection
                  handleOnClick={() => setShowRodalOutlets(true)}
                  inputLabel={resources["Outlets"]}
                  isMandatory={true}
                  idName={"selectedOutlets"}
                  selectText={resources["Select"]}
                  valueName={
                    selectedOutletsData.selectedOutlets.length > 0
                      ? selectedOutletsData.selectedOutletsName
                      : resources["NoneSelectedYet"]
                  }
                  disabled={isDeleted}
                />
                <CustomSelectionSection
                  selectText={resources["Select"]}
                  handleOnClick={() => setShowRodalRoles(true)}
                  inputLabel={resources["Roles"]}
                  isMandatory={true}
                  idName={"selectedRoles"}
                  valueName={
                    selectedRolesData.selectedRoles.length > 0
                      ? selectedRolesData.selectedRolesName
                      : resources["NoneSelectedYet"]
                  }
                  disabled={isDeleted}
                />
                <CustomSelectionSection
                  handleOnClick={() => setShowRodalPermissions(true)}
                  inputLabel={resources["Permissions"]}
                  isMandatory={false}
                  idName={"selectedPermissions"}
                  selectText={resources["Select"]}
                  valueName={
                    selectedPermissionsData.selectedPermissions.length > 0
                      ? selectedPermissionsData.selectedPermissionsName
                      : resources["NoneSelectedYet"]
                  }
                  disabled={isDeleted}
                />
                <CustomSelectionSection
                  handleOnClick={
                    selectedOutletsData.savedMainOutlet > 0
                      ? () => handleShowRodalChatGroups()
                      : () => {
                          return true;
                        }
                  }
                  inputLabel={resources["ChatGroups"]}
                  isMandatory={false}
                  idName={"selectedChatGroups"}
                  selectText={resources["Select"]}
                  valueName={
                    selectedChatGroupsData.selectedChatGroups.length > 0
                      ? selectedChatGroupsData.selectedChatGroupsName
                      : resources["NoneSelectedYet"]
                  }
                  linkClassName={displayChatGroups}
                  disabled={isDeleted}
                />
                <CustomSelectionSection
                  handleOnClick={
                    selectedOutletsData.savedMainOutlet > 0
                      ? () => handleShowRodalDossierStacks(true)
                      : () => {
                          return true;
                        }
                  }
                  inputLabel={resources["DossierStacks"]}
                  isMandatory={false}
                  idName={"selectedDossierStacks"}
                  selectText={resources["Select"]}
                  valueName={
                    selectedDossierStacksData.selectedDossierStacks.length > 0
                      ? selectedDossierStacksData.selectedDossierStacksName
                      : resources["NoneSelectedYet"]
                  }
                  linkClassName={displayDossierStacks}
                  disabled={isDeleted}
                />
              </Box>
            </Box>
            {outletUserId > 0 && imageProfileData != null && (
              <CreatedByInfo
                resources={resources}
                createdOn={imageProfileData.createdOn}
                createdBy={imageProfileData.createdBy}
              />
            )}
          </Form>
        </Paper>
        {!isDeleted && (
          /* select Mobo Outlets for outletUser */
          <>
            {/* select Mobo Outlets for outletUser */}
            <MoboOutletsRodal
              selectedOutletsData={selectedOutletsData}
              handleConfirmSelectedOutlets={handleConfirmSelectedOutlets}
              showRodalOutlets={showRodalOutlets}
              handleCloseRodalOutlets={() => setShowRodalOutlets(false)}
              resources={resources}
              accessibleMoboOutlets={accessibleMoboOutlets}
            />
            {/* select Roles  */}
            <RolesRodal
              resources={resources}
              showRodalRoles={showRodalRoles}
              handleCloseRodalRoles={() => setShowRodalRoles(false)}
              selectedRoles={selectedRolesData.selectedRoles}
              selectedRolesName={selectedRolesData.selectedRolesName}
              handleConfirmSelectedRoles={handleConfirmSelectedRoles}
            />
            {/* select Permissions */}
            <PermissionsRodal
              resources={resources}
              showRodalPermissions={showRodalPermissions}
              handleCloseRodalPermissions={() => setShowRodalPermissions(false)}
              handleConfirmSelectedPermissions={
                handleConfirmSelectedPermissions
              }
              selectedPermissions={selectedPermissionsData.selectedPermissions}
              selectedPermissionsName={
                selectedPermissionsData.selectedPermissionsName
              }
            />
            {/* select ChatGroups */}
            <ChatGroupsRodal
              resources={resources}
              loadingChatGroups={loadingChatGroups}
              showRodalChatGroups={showRodalChatGroups}
              handleCloseRodalChatGroups={() => setShowRodalChatGroups(false)}
              handleConfirmSelectedChatGroups={handleConfirmSelectedChatGroups}
              chatGroups={filterChatGroups}
              data={selectedChatGroupsData}
            />
            {/* select DossierStacks */}
            <DossierStacksRodal
              resources={resources}
              loadingDossierStacks={loadingDossierStacks}
              showRodalDossierStacks={showRodalDossierStacks}
              handleCloseRodalDossierStacks={() =>
                setShowRodalDossierStacks(false)
              }
              dossierStacks={filterDossierStacks}
              data={selectedDossierStacksData}
              handleConfirmSelectedDossierStacks={
                handleConfirmSelectedDossierStacks
              }
            />
          </>
        )}
      </Box>
    );
  };

  const renderMain = () => {
    if (loadingOutletUser && outletUserId)
      return (
        <Box className="centered-loader">
          <CircularProgress />
        </Box>
      );

    return (
      <Box
        className={"py-4 grey " + (loadingOutletUser ? "fadeOut" : "fadeIn")}
        style={{ textAlign: "center" }}
      >
        {renderForm()}
      </Box>
    );
  };

  return (
    <Box>
      {state.redirect && (
        <DelayedRedirect
          to={{
            pathname: state.redirect,
            state: {
              refreshGrid: outletUserWasCreated,
              outletId: outletUserId,
            },
          }}
          delay={250}
        />
      )}
      <Dialog
        fullScreen
        open={state.open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBarComponent
          idName="outletuser"
          title={
            isEditMode
              ? resources["EditOutletUserTitle"]
              : resources["AddNewOutletUserTitle"]
          }
          resources={resources}
          handleClose={handleClose}
          handleSubmit={saveOutletUser}
          disabled={requestInProgress || isDeleted}
        />
        <Box className="container">{renderMain()}</Box>
      </Dialog>
    </Box>
  );
};

export default OutletUser;
