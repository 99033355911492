import { FormControlLabel, Box, Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Rodal from "rodal";
import "toastr/build/toastr.min.css";
import { fetchRoles } from "../../../../../services/OutletUsers/outletUserService.js";
import CenterCircularProgress from "../../../../Common/CircularProgress/CenterCircularProgress";
import RodalHeader from "../../../../Common/Headers/RodalHeader";
import ModalFooterButton from "./../common/ModalFooterButton";

const RolesRodal = ({
  resources,
  showRodalRoles,
  handleCloseRodalRoles,
  selectedRoles,
  selectedRolesName,
  handleConfirmSelectedRoles,
}) => {
  const [selectedRolesChecked, setSelectedRolesChecked] =
    useState(selectedRoles);
  const [selectedRolesCheckedName, setSelectedRolesCheckedName] =
    useState(selectedRolesName);
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);

  const getRoles = async () => {
    const rolesSorted = await fetchRoles();
    setRoles(rolesSorted);
    setLoadingRoles(false);
  };

  useEffect(() => {
    getRoles();
  }, []);

  const closeRodalRoles = () => {
    setSelectedRolesChecked(selectedRoles);
    setSelectedRolesCheckedName(selectedRolesName);

    handleCloseRodalRoles();
  };

  const getRolesNamesChecked = (newSelectedRolesCheckedArray) => {
    return newSelectedRolesCheckedArray
      .map((id) => {
        const role = roles.find((x) => x.id === id);
        return role ? role.description : "";
      })
      .filter((desc) => desc !== "");
  };

  const handleSelectRoles = (e) => {
    const selectedRoleId = parseInt(e.target.value);
    const role = roles.find((x) => x.id === selectedRoleId);

    if (!role) return;
    let newSelectedRolesChecked = new Set(selectedRolesChecked);

    if (e.target.checked) {
      newSelectedRolesChecked.add(selectedRoleId);
    } else if (newSelectedRolesChecked.has(selectedRoleId)) {
      newSelectedRolesChecked.delete(selectedRoleId);
    }

    const newSelectedRolesCheckedArray = Array.from(newSelectedRolesChecked);
    const newSelectedRolesNamesCheckedArray = getRolesNamesChecked(
      newSelectedRolesCheckedArray
    ).join(", ");

    setSelectedRolesChecked(newSelectedRolesCheckedArray);
    setSelectedRolesCheckedName(newSelectedRolesNamesCheckedArray);
  };

  const renderSelectRoles = () => {
    const rolesMapped = roles.map((x) => (
      <Form.Group key={`roles${x.id}`} className=" mb-0 text-left">
        <FormControlLabel
          className="col-md-11 text-right label-mt my-auto p-0"
          control={
            <Checkbox
              id={`roles-indicators-is-active${x.id}`}
              name={`roles-${x.id}`}
              style={{ padding: "5px 9px!important" }}
              checked={selectedRolesChecked.indexOf(x.id) !== -1}
              onChange={handleSelectRoles}
              value={x.id}
              color="primary"
            />
          }
          label={x.description}
        />
      </Form.Group>
    ));
    return rolesMapped;
  };

  return (
    <Rodal
      visible={showRodalRoles}
      onClose={() => closeRodalRoles()}
      width={500}
      height={550}
    >
      {loadingRoles ? (
        <CenterCircularProgress />
      ) : (
        <Box>
          <RodalHeader title={resources["Roles"]} />
          <Box className="modal-body mb-0 py-2">
            <Form.Group
              className=" overflow-auto"
              style={{ height: "400px", maxHeight: "400px" }}
            >
              {renderSelectRoles()}
            </Form.Group>
          </Box>
          <ModalFooterButton
            id="btnOkRoles"
            onClick={() =>
              handleConfirmSelectedRoles(
                selectedRolesChecked,
                selectedRolesCheckedName
              )
            }
            buttonText={resources["Ok"]}
            isDisabled={selectedRolesChecked?.length < 1}
          />
          <span className="rodal-close" />
        </Box>
      )}
    </Rodal>
  );
};

export default RolesRodal;
