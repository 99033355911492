import { TextField, Box, InputAdornment } from "@material-ui/core";
import React from "react";

const DecimalFieldComponent = ({
  value,
  handleChange,
  labelText,
  id,
  marginRight,
  placeholderText,
  startSign,
}) => {
  const validateAndFormatInput = (input) => {
    const decimalRegex = /^[0-9]{0,6}(\.[0-9]{0,2})?$/;

    if (decimalRegex.test(input)) {
      const numericValue = parseFloat(input);
      if (input === "" || (numericValue >= 0 && numericValue <= 999999.99)) {
        return true;
      }
    }
    return false;
  };

  const handleKeyDown = (e) => {
    const inputValue = e.target.value;
    const char = e.key;

    const isValid = validateAndFormatInput(inputValue + char);
    if (
      !isValid &&
      char !== "Backspace" &&
      char !== "Delete" &&
      char !== "Tab"
    ) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    if (validateAndFormatInput(inputValue)) {
      handleChange(inputValue);
    }
  };

  const commonTextFieldProps = {
    className: "col-12",
    type: "text",
    id: `decimal-input-${id}`,
    value,
    label: labelText,
    variant: "outlined",
    name: id,
    onChange: handleInputChange,
    onKeyDown: handleKeyDown,
    placeholder: placeholderText,
    InputLabelProps: {
      style: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
        maxWidth: "100%",
      },
    },
    InputProps: {
      pattern: "^[0-9]{0,6}(\\.[0-9]{0,2})?$",
      maxLength: 9,
    },
    fullWidth: true,
  };

  if (startSign) {
    commonTextFieldProps.InputProps = {
      ...commonTextFieldProps.InputProps,
      startAdornment: (
        <InputAdornment position="start" style={{ marginRight: 2 }}>
          {value ? startSign : null}
        </InputAdornment>
      ),
    };
  }

  return (
    <Box
      display="flex"
      marginBottom={2}
      flexDirection="column"
      marginRight={marginRight}
    >
      <TextField {...commonTextFieldProps} />
    </Box>
  );
};

export default DecimalFieldComponent;
