import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import "toastr/build/toastr.min.css";
import { FormControlLabel, Box, Checkbox } from "@material-ui/core";
import "toastr/build/toastr.min.css";
import Rodal from "rodal";
import ModalFooterButton from "./../common/ModalFooterButton";
import RodalHeader from "../../../../Common/Headers/RodalHeader";
import CenterCircularProgress from "../../../../Common/CircularProgress/CenterCircularProgress";

const ChatGroupsRodal = ({
  resources,
  loadingChatGroups,
  showRodalChatGroups,
  handleCloseRodalChatGroups,
  handleConfirmSelectedChatGroups,
  chatGroups,
  data,
}) => {
  const [chatGroupIds, setChatGroupIds] = useState([]);
  const [chatGroupNames, setChatGroupNames] = useState("");

  useEffect(() => {
    setChatGroupIds(data.selectedChatGroups || []);
    setChatGroupNames(data.selectedChatGroupsName || "");
  }, [data]);

  const closeRodalChatGroups = () => {
    setChatGroupIds(data.selectedChatGroups);
    setChatGroupNames(data.selectedChatGroupsName);

    handleCloseRodalChatGroups();
  };

  const handleSelectChatGroups = (e) => {
    const selectedChatGroupId = parseInt(e.target.value);
    const chatGroup = chatGroups.find((x) => x.id === selectedChatGroupId);
    if (!chatGroup) return;

    const chatGroupDesc = chatGroup.description;
    let newSelectedChatGroupsChecked = [...chatGroupIds];
    let newSelectedChatGroupsCheckedName = chatGroupNames;

    if (e.target.checked) {
      if (!newSelectedChatGroupsChecked.includes(selectedChatGroupId)) {
        newSelectedChatGroupsChecked.push(selectedChatGroupId);
        newSelectedChatGroupsCheckedName = newSelectedChatGroupsCheckedName
          ? `${newSelectedChatGroupsCheckedName}, ${chatGroupDesc}`
          : chatGroupDesc;
      }
    } else {
      newSelectedChatGroupsChecked = newSelectedChatGroupsChecked.filter(
        (id) => id !== selectedChatGroupId
      );
      const regex = new RegExp(`(, )?${chatGroupDesc}(, )?`);
      newSelectedChatGroupsCheckedName =
        newSelectedChatGroupsCheckedName.replace(regex, (match, p1, p2) =>
          p1 && p2 ? ", " : ""
        );
    }

    setChatGroupIds(newSelectedChatGroupsChecked);
    setChatGroupNames(newSelectedChatGroupsCheckedName);
  };

  const renderChatGroups = () => {
    const chatGroupsMapped = chatGroups.map((x) => (
      <Form.Group key={`chatGroup${x.id}`} className=" mb-0 text-left">
        <FormControlLabel
          className="col-md-11 text-right label-mt my-auto p-0"
          control={
            <Checkbox
              id={`chatGroup-indicators-is-active${x.id}`}
              name={`chatGroup-${x.id}`}
              checked={chatGroupIds.indexOf(x.id) !== -1}
              onChange={handleSelectChatGroups}
              value={x.id}
              color="primary"
              style={{ padding: "5px 9px!important" }}
            />
          }
          label={x.description}
        />
      </Form.Group>
    ));
    return chatGroupsMapped;
  };

  return (
    <Rodal
      visible={showRodalChatGroups}
      onClose={() => closeRodalChatGroups()}
      width={500}
      height={550}
    >
      {loadingChatGroups ? (
        <CenterCircularProgress />
      ) : (
        <Box>
          <RodalHeader title={resources["ChatGroups"]} />
          <Box className="modal-body mb-0 py-2">
            <Form.Group
              className=" overflow-auto "
              style={{ height: "400px", maxHeight: "400px" }}
            >
              {renderChatGroups()}
            </Form.Group>
          </Box>
          <ModalFooterButton
            id="btnOkChatGroups"
            onClick={() =>
              handleConfirmSelectedChatGroups(chatGroupIds, chatGroupNames)
            }
            buttonText={resources["Ok"]}
          />
          <span className="rodal-close" />
        </Box>
      )}
    </Rodal>
  );
};

export default ChatGroupsRodal;
