import { Box, InputLabel, ThemeProvider } from "@material-ui/core";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Rodal from "rodal";
import "toastr/build/toastr.min.css";
import {
  DisplayMessage,
  ValidatePin,
  ValidatePassword,
} from "../../../../services/helpers.js";
import {
  resetPassword,
  saveNewPin,
  updatePassword,
} from "../../../../services/OutletUsers/outletUserService.js";
import { SelectTheme } from "../../../../utils/utils.js";
import ActionButton from "../../../Common/Buttons/ActionButton.js";
import RodalHeader from "../../../Common/Headers/RodalHeader";
import NumericFieldComponent from "./../../../Common/Inputs/NumericFieldComponent";
import TextFieldComponent from "./../../../Common/Inputs/TextFieldComponent";

const ResetSection = ({ resources, outletUserId, disabled }) => {
  const [showRodalNewPin, setShowRodalNewPin] = useState(false);
  const [showRodalResetPassword, setShowRodalResetPassword] = useState(false);
  const [showRodalNewPassword, setShowRodalNewPassword] = useState(false);

  const [pin, setPin] = useState("");
  const [password, setPassword] = useState("");

  // PIN
  const handleShowRodalNewPin = (showModal) => {
    if (showModal) {
      setPin("");
    }
    setShowRodalNewPin(showModal);
  };

  const handleSaveNewPin = async () => {
    if (pin === "" || pin === null || !ValidatePin(pin)) {
      DisplayMessage(resources["InvalidPin"], "error");
      setPin("");
      return;
    }
    var json = JSON.stringify({
      id: outletUserId,
      pin: pin,
    });

    const isSaved = await saveNewPin(json);
    if (isSaved) {
      DisplayMessage(resources["NewPinWasSaved"], "success");
      setShowRodalNewPin(false);
    }
  };

  // RESET PASSWORD
  const handleSaveResetPassword = async () => {
    const isReseted = await resetPassword(outletUserId);
    if (isReseted) {
      DisplayMessage(resources["NewPasswordWasSentByEmail"], "success");
      setShowRodalResetPassword(false);
    }
  };

  //NEW PASSWORD
  const handleShowRodalNewPassword = (showModal) => {
    if (showModal) {
      setPassword("");
    }
    setShowRodalNewPassword(showModal);
  };

  const handleSaveNewPassword = async () => {
    if (!password || !ValidatePassword(password)) {
      DisplayMessage(resources["InvalidPassword"], "error");
      setPassword("");
      return;
    }
    var json = JSON.stringify({
      password: password,
    });

    const wasUpdated = await updatePassword(outletUserId, json);
    if (wasUpdated) {
      DisplayMessage(resources["NewPasswordWasSaved"], "success");
      setShowRodalNewPassword(false);
    }
  };

  return (
    <>
      <Form.Group className="pt-3 pb-3 d-flex">
        <Box className="col-3" />
        <Box className="text-center col-6">
          <ActionButton
            idName="newPin"
            className="btn-secondary action-white-button"
            label={resources["NewPin"]}
            onClick={() => handleShowRodalNewPin(true)}
            disabled={disabled}
          />
          <ActionButton
            idName="resetPassword"
            label={resources["ResetPassword"]}
            onClick={() => setShowRodalResetPassword(true)}
            className="btn-secondary action-white-button ml-2"
            disabled={disabled}
          />
          <ActionButton
            idName="newPassword"
            label={resources["NewPassword"]}
            onClick={() => handleShowRodalNewPassword(true)}
            className="btn-secondary action-white-button ml-2"
            disabled={disabled}
          />
        </Box>
      </Form.Group>

      {/* reset pin */}
      <Rodal
        visible={showRodalNewPin}
        onClose={() => handleShowRodalNewPin(false)}
        className="rodal-height-fit-content"
      >
        <RodalHeader title={resources["NewPin"]} />
        <Box className="modal-body mt-2">
          <Form.Group className="overflow-auto">
            <Form.Group className="mt-2 d-flex mb-0">
              <Box className="col-4 mt-2 ">
                <InputLabel className="text-center">
                  {resources["Pin"]}
                </InputLabel>
              </Box>
              <Box className="col-8 p-0" marginRight="4px">
                <ThemeProvider theme={SelectTheme}>
                  <NumericFieldComponent
                    id="outletuser-pin"
                    value={pin}
                    isValid={pin && ValidatePin(pin)}
                    labelText={resources["Pin"]}
                    maxLength={9999}
                    minLength={1000}
                    handleChange={(e) =>
                      setPin(
                        e.target.value === "" ? "" : parseInt(e.target.value)
                      )
                    }
                    helperText={resources["InvalidPin"]}
                    isMandatory={true}
                  />
                </ThemeProvider>
              </Box>
            </Form.Group>
          </Form.Group>
        </Box>
        <Box className="modal-footer mt-0 pt-0">
          <ActionButton
            idName="btnCancelNewPin"
            label={resources["Cancel"]}
            onClick={() => handleShowRodalNewPin(false)}
            className="btn-secondary action-white-button"
          />
          <ActionButton
            idName="btnSaveNewPin"
            label={resources["Save"]}
            onClick={() => handleSaveNewPin(false)}
            className="btn-primary action-blue-button"
            disabled={!ValidatePin(pin)}
          />
        </Box>
        <span className="rodal-close" />
      </Rodal>

      {/* reset password */}
      <Rodal
        visible={showRodalResetPassword}
        onClose={() => setShowRodalResetPassword(false)}
      >
        <Box className="modal-body m-4">
          {resources["AreYouSureYouWantToResetPassword"]}
        </Box>
        <Box className="modal-footer mt-5 pt-0">
          <ActionButton
            idName="btnCancelResetPassword"
            label={resources["Cancel"]}
            onClick={() => setShowRodalResetPassword(false)}
            className="btn-secondary action-white-button"
          />
          <ActionButton
            idName="btnResetPassword"
            label={resources["Yes"]}
            onClick={handleSaveResetPassword}
            className="btn-primary action-blue-button"
          />
        </Box>
        <span className="rodal-close"></span>
      </Rodal>

      {/* new password */}
      <Rodal
        visible={showRodalNewPassword}
        onClose={() => handleShowRodalNewPassword(false)}
        className="rodal-height-fit-content"
      >
        <RodalHeader title={resources["NewPassword"]} />
        <Box className="modal-body mt-2">
          <Form.Group className="overflow-auto">
            <Form.Group className="mt-2 d-flex mb-0">
              <Box className="col-4 mt-2 pl-0">
                <InputLabel className="text-center">
                  {resources["NewPassword"]}
                </InputLabel>
              </Box>
              <Box className="col-6 p-0">
                <ThemeProvider theme={SelectTheme}>
                  <TextFieldComponent
                    isValid={ValidatePassword(password)}
                    id="outletuser-password"
                    value={password}
                    labelText={resources["Password"]}
                    name="password"
                    handleChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    maxLength={14}
                    autoComplete="off"
                    type="password"
                    helperText={resources["InvalidPassword"]}
                    tooltipText={resources["PasswordValidationRules"]}
                  />
                </ThemeProvider>
              </Box>
            </Form.Group>
          </Form.Group>
        </Box>
        <Box className="modal-footer mt-0 pt-0">
          <ActionButton
            idName="btnCancelNewPassword"
            label={resources["Cancel"]}
            onClick={() => handleShowRodalNewPassword(false)}
            className="btn-secondary action-white-button"
          />
          <ActionButton
            idName="btnSaveNewPassword"
            label={resources["Save"]}
            onClick={() => handleSaveNewPassword(false)}
            className="btn-primary action-blue-button"
            disabled={!ValidatePassword(password)}
          />
        </Box>
        <span className="rodal-close" />
      </Rodal>
    </>
  );
};

export default ResetSection;
