import { Box, TextField, FormHelperText } from "@material-ui/core";
import React from "react";

const NumericFieldComponent = ({
  value,
  handleChange,
  labelText,
  id,
  maxLength,
  minLength,
  helperText,
  isValid = true,
  disabled = false,
  isMandatory = false,
}) => {
  return (
    <Box display={"flex"} marginBottom={2} flexDirection={"column"}>
      <TextField
        className="col-12"
        type="number"
        id={`${id}`}
        value={value ?? ""}
        label={labelText}
        variant="outlined"
        name={id}
        onChange={handleChange}
        inputProps={{ max: maxLength, min: minLength ?? 0 }}
        InputLabelProps={{
          style: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            maxWidth: "100%",
          },
        }}
        disabled={disabled}
      />
      {isMandatory && helperText && (
        <FormHelperText
          style={{ marginLeft: "14px", marginRight: "14px" }}
          error={!isValid}
        >
          {helperText}
        </FormHelperText>
      )}

      {!isMandatory && !isValid && helperText && (
        <FormHelperText
          style={{ marginLeft: "14px", marginRight: "14px" }}
          error={!isValid}
        >
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
};

export default NumericFieldComponent;
