import React from "react";
import Form from "react-bootstrap/Form";
import { SelectTheme } from "../../../../utils/utils.js";
import { ThemeProvider } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { Box } from "@material-ui/core";

const ProfileImage = ({
  imageProfileData,
  removeProfileImage,
  handleFileChange,
  file,
  resources,
  disabled,
}) => {
  const renderProfileImage = () => {
    if (imageProfileData && imageProfileData.profileImageUri) {
      return (
        <Box className="profile mb-2" display={"flex"}>
          <Box>
            <img
              alt="profile"
              src={imageProfileData.profileImageUri}
              className={disabled ? "profile-image-disabled" : "profile-image"}
              height={100}
              disabled={disabled}
            />
          </Box>
          {!disabled && (
            <Box marginLeft={"10px"}>
              <button
                className="delete-image"
                type="button"
                onClick={removeProfileImage}
              >
                <i className="fas fa-trash-alt"></i>
                <span className="MuiTouchRipple-root-2476"></span>
              </button>
            </Box>
          )}
        </Box>
      );
    }
    return (
      <ThemeProvider theme={SelectTheme}>
        <DropzoneArea
          className="col-12"
          acceptedFiles={["image/*"]}
          filesLimit={1}
          showAlerts={false}
          showPreviews={true}
          dropzoneClass={
            disabled
              ? "dropzone profile disabled"
              : file
              ? "dropzone-hidden profile"
              : "dropzone profile"
          }
          dropzoneParagraphClass={"dropzone-p"}
          previewText={""}
          maxFileSize={10000000}
          dropzoneText={resources["ProfileImage"]}
          onChange={handleFileChange}
          clearOnUnmount={false}
          key={1}
          showFileNames={true}
          disabled={disabled}
        />
      </ThemeProvider>
    );
  };

  return <Form.Group className="d-flex">{renderProfileImage()}</Form.Group>;
};

export default ProfileImage;
