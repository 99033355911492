import React from "react";

const ActionButton = ({ onClick, label, idName, disabled, className = "" }) => {
  return (
    <button
      className={`btn ${className}`}
      type="button"
      onClick={onClick}
      id={idName}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default ActionButton;
