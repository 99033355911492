import React from "react";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";

const CreatedByInfo = ({
  resources,
  createdOn,
  createdBy,
}) => {
  return (
    <Box display="flex" alignItems="center" className="mb-3 mt-1" justifyContent="center">
        <Typography variant="body2">
          {`${resources["CreatedOn"]} ${moment(createdOn).format(
            "DD/MM/YYYY"
          )} ${resources["By"]} ${createdBy}`}
        </Typography>
    </Box>
  );
};

export default CreatedByInfo;
