import {
  FormControlLabel,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Rodal from "rodal";
import "toastr/build/toastr.min.css";
import CenterCircularProgress from "../../../../Common/CircularProgress/CenterCircularProgress";
import RodalHeader from "../../../../Common/Headers/RodalHeader";
import ModalFooterButton from "./../common/ModalFooterButton";

const MoboOutletsRodal = ({
  showRodalOutlets,
  accessibleMoboOutlets,
  resources,
  selectedOutletsData,
  handleConfirmSelectedOutlets,
  handleCloseRodalOutlets,
}) => {
  const [selectedOutletsChecked, setSelectedOutletsChecked] = useState(
    selectedOutletsData.selectedOutlets
  );
  const [selectedOutletsCheckedName, setSelectedOutletsCheckedName] = useState(
    selectedOutletsData.selectedOutletsName
  );
  const [selectMainOutlet, setSelectMainOutlet] = useState(
    selectedOutletsData.savedMainOutlet
  );

  const selectOutlet = (e) => {
    const selectedOutletId = parseInt(e.target.value);

    const outletDesc = accessibleMoboOutlets.find(
      (x) => x.id === selectedOutletId
    ).description;
    let outletsChecked = new Set(selectedOutletsChecked);
    let outletsCheckedNamesArray = selectedOutletsCheckedName
      ? selectedOutletsCheckedName.split(", ")
      : [];

    if (e.target.checked) {
      outletsChecked.add(selectedOutletId);
      outletsCheckedNamesArray.push(outletDesc);
    } else {
      const index = selectedOutletsChecked.indexOf(selectedOutletId);
      if (selectedOutletId !== selectMainOutlet && index !== -1) {
        outletsChecked.delete(selectedOutletId);
        outletsCheckedNamesArray = outletsCheckedNamesArray.filter(
          (desc) => desc !== outletDesc
        );
      }
    }
    setSelectedOutletsChecked(Array.from(outletsChecked));
    setSelectedOutletsCheckedName(outletsCheckedNamesArray.join(", "));
  };

  const changeMainOutlet = (e) => {
    const mainOutletValue = parseInt(e.target.value);

    if (
      mainOutletValue > 0 &&
      !selectedOutletsChecked.includes(mainOutletValue)
    ) {
      const outlet = accessibleMoboOutlets.find(
        (x) => x.id === mainOutletValue
      );
      if (outlet) {
        const newSelectedOutletsChecked = [
          ...selectedOutletsChecked,
          mainOutletValue,
        ];
        const newSelectedOutletsCheckedName = selectedOutletsCheckedName
          ? `${selectedOutletsCheckedName}, ${outlet.description}`
          : outlet.description;

        setSelectedOutletsChecked(newSelectedOutletsChecked);
        setSelectedOutletsCheckedName(newSelectedOutletsCheckedName);
      }
    }

    setSelectMainOutlet(mainOutletValue);
  };

  const renderSelectMainOutlet = () => {
    if (!accessibleMoboOutlets) return;

    const sortedOutlets = accessibleMoboOutlets
      .slice()
      .sort((a, b) => a.description.localeCompare(b.description));

    return sortedOutlets.map((x) => (
      <option key={"outlets" + x.id} value={x.id}>
        {x.description}
      </option>
    ));
  };

  const renderSelectOutlets = () => {
    if (!accessibleMoboOutlets) return;

    const sortedOutlets = accessibleMoboOutlets
      .slice()
      .sort((a, b) => a.description.localeCompare(b.description));

    return sortedOutlets.map((x) => (
      <Form.Group key={`moboOutlets${x.id}`} className="mb-0 text-left">
        <FormControlLabel
          className="col-md-11 text-right label-mt my-auto p-0"
          control={
            <Checkbox
              id={`moboOutlets-indicators-is-active${x.id}`}
              name={`moboOutlets-${x.id}`}
              style={{ padding: "5px 9px!important" }}
              checked={selectedOutletsChecked?.indexOf(x.id) !== -1}
              onChange={selectOutlet}
              value={x.id}
              color="primary"
            />
          }
          label={x.description}
        />
      </Form.Group>
    ));
  };

  const closeRodalOutlets = () => {
    setSelectedOutletsChecked(selectedOutletsData.selectedOutlets);
    setSelectedOutletsCheckedName(selectedOutletsData.selectedOutletsName);
    setSelectMainOutlet(selectedOutletsData.savedMainOutlet);

    handleCloseRodalOutlets();
  };

  return (
    <Rodal
      visible={showRodalOutlets}
      onClose={() => closeRodalOutlets()}
      width={500}
      height={550}
    >
      {accessibleMoboOutlets === undefined ? (
        <CenterCircularProgress />
      ) : (
        <Box>
          <RodalHeader title={resources["Outlets"]} />
          <Box
            className="modal-body mb-0 pb-0"
            style={{ height: "430px", maxHeight: "430px" }}
          >
            <Form.Group
              className=" overflow-auto"
              style={{ maxHeight: "300px" }}
            >
              {renderSelectOutlets()}
            </Form.Group>
            <hr className="MuiDivider-root mb-2" />
            <Form.Group className="pt-3" style={{ minHeight: "75px" }}>
              <FormControl className="col-12" variant="outlined">
                <InputLabel htmlFor="outlined-outlets">
                  {resources["MainOutlet"]}
                </InputLabel>
                <Select
                  label={resources["MainOutlet"]}
                  native
                  value={selectMainOutlet}
                  name="mainOutlet"
                  inputProps={{
                    name: "mainOutlet",
                    id: "outlined-outlets",
                  }}
                  onChange={changeMainOutlet}
                >
                  <option value={0}>{resources["DefaultDropdownValue"]}</option>
                  {renderSelectMainOutlet()}
                </Select>
                {selectMainOutlet < 1 && (
                  <FormHelperText error={true}>
                    {resources["SelectionOfMainOutletIsMandatory"]}
                  </FormHelperText>
                )}
              </FormControl>
            </Form.Group>
          </Box>
          <ModalFooterButton
            id="btOkOutlets"
            onClick={() =>
              handleConfirmSelectedOutlets({
                selectedOutletsChecked: selectedOutletsChecked,
                selectedOutletsCheckedName: selectedOutletsCheckedName,
                selectMainOutlet: selectMainOutlet,
              })
            }
            buttonText={resources["Ok"]}
            isDisabled={
              selectedOutletsChecked?.length < 1 || selectMainOutlet === 0
            }
          />
          <span className="rodal-close" />
        </Box>
      )}
    </Rodal>
  );
};

export default MoboOutletsRodal;
