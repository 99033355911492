import {
  InputLabel,
  Select,
  FormControl,
  Box,
  FormHelperText,
} from "@material-ui/core";
import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { SelectTheme } from "../../../utils/utils.js";

const DropdownComponent = ({
  value,
  handleChange,
  options,
  labelText,
  id,
  disabled = false,
  helperText,
  error,
}) => {
  return (
    <Box className="d-flex form-group">
      <ThemeProvider theme={SelectTheme}>
        <FormControl className="col-12 main-dropdown" variant="outlined">
          <InputLabel id={id + "_dropdown"} htmlFor={`${id}_selected`}>
            {labelText}
          </InputLabel>
          <Select
            disabled={disabled ?? false}
            native
            id={`${id}_selected`}
            value={value}
            label={labelText}
            onChange={handleChange}
          >
            {options.map(({ key, name }) => (
              <option key={`${id}-${key}`} value={key}>
                {name}
              </option>
            ))}
          </Select>
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </FormControl>
      </ThemeProvider>
    </Box>
  );
};

export default DropdownComponent;
