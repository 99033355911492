import { Box, InputLabel } from "@material-ui/core";
import React from "react";

const RodalHeader = ({ title }) => {
  return (
    <Box
      className="modal-header text-center mb-0 pb-0"
      borderBottom="1px solid white"
    >
      <InputLabel className="text-center">{title}</InputLabel>
    </Box>
  );
};

export default RodalHeader;
