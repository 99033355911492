import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

const CenterCircularProgress = () => {
  return (
    <Box className="centered-loader">
      <CircularProgress />
    </Box>
  );
};

export default CenterCircularProgress;
