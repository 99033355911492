import { FormControlLabel, Box, Checkbox } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Rodal from "rodal";
import "toastr/build/toastr.min.css";
import CenterCircularProgress from "../../../../Common/CircularProgress/CenterCircularProgress";
import RodalHeader from "../../../../Common/Headers/RodalHeader";
import ModalFooterButton from "./../common/ModalFooterButton";

const DossierStacksRodal = ({
  resources,
  loadingDossierStacks,
  showRodalDossierStacks,
  handleCloseRodalDossierStacks,
  dossierStacks,
  data,
  handleConfirmSelectedDossierStacks,
}) => {
  const [dossierStacksIds, setDossierStacksIds] = useState([]);
  const [dossierStacksNames, setDossierStacksNames] = useState("");

  useEffect(() => {
    setDossierStacksIds(data.selectedDossierStacks || []);
    setDossierStacksNames(data.selectedDossierStacksName || "");
  }, [data]);

  const closeRodalDossierStacks = () => {
    setDossierStacksIds(data.selectedDossierStacks);
    setDossierStacksNames(data.selectedDossierStacksName);

    handleCloseRodalDossierStacks();
  };

  const getDossierStacksCheckedNames = (
    selectedDossierStacksChecked,
    dossierStacks
  ) => {
    return selectedDossierStacksChecked
      .map((id) => {
        const stack = dossierStacks.find((x) => x.id === id);
        return stack ? stack.description : "";
      })
      .filter((desc) => desc !== "");
  };

  const handleSelectDossierStacks = (e) => {
    const selectedDossierStackId = parseInt(e.target.value);
    const dossierStack = dossierStacks.find(
      (x) => x.id === selectedDossierStackId
    );

    if (!dossierStack) return; // Exit early if stack is not found

    const newSelectedDossierStacksChecked = new Set(dossierStacksIds);

    if (e.target.checked) {
      newSelectedDossierStacksChecked.add(selectedDossierStackId);
    } else {
      newSelectedDossierStacksChecked.delete(selectedDossierStackId);
    }

    const newSelectedDossierStacksCheckedArray = Array.from(
      newSelectedDossierStacksChecked
    );
    const newSelectedDossierStacksCheckedName = getDossierStacksCheckedNames(
      newSelectedDossierStacksCheckedArray,
      dossierStacks
    ).join(", ");

    setDossierStacksIds(newSelectedDossierStacksCheckedArray);
    setDossierStacksNames(newSelectedDossierStacksCheckedName);
  };

  const renderDossierStacks = () => {
    const dossierStacksMapped = dossierStacks.map((x) => (
      <Form.Group key={`dossierStacks${x.id}`} className=" mb-0 text-left">
        <FormControlLabel
          className="col-md-11 text-right label-mt my-auto p-0"
          control={
            <Checkbox
              id={`dossierStacks-indicators-is-active${x.id}`}
              name={`dossierStacks-${x.id}`}
              style={{ padding: "5px 9px!important" }}
              checked={dossierStacksIds.indexOf(x.id) !== -1}
              value={x.id}
              onChange={handleSelectDossierStacks}
              color="primary"
            />
          }
          label={x.fullDescription}
        />
      </Form.Group>
    ));
    return dossierStacksMapped;
  };

  return (
    <Rodal
      visible={showRodalDossierStacks}
      onClose={() => closeRodalDossierStacks()}
      width={500}
      height={550}
    >
      {loadingDossierStacks ? (
        <CenterCircularProgress />
      ) : (
        <Box>
          <RodalHeader title={resources["DossierStacks"]} />
          <Box className="modal-body mb-0 py-2">
            <Form.Group
              className=" overflow-auto"
              style={{ height: "400px", maxHeight: "400px" }}
            >
              {renderDossierStacks()}
            </Form.Group>
          </Box>
          <ModalFooterButton
            id="btnOkDossierStacks"
            onClick={() =>
              handleConfirmSelectedDossierStacks(
                dossierStacksIds,
                dossierStacksNames
              )
            }
            buttonText={resources["Ok"]}
          />
          <span className="rodal-close" />
        </Box>
      )}
    </Rodal>
  );
};
export default DossierStacksRodal;
